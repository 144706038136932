

































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes } from "@/libs/appconst";

import AgentEditDialog from "./components/AgentEditDialog.vue";
import AgentMoneyDialog from "./components/AgentMoneyDialog.vue"

@Component({
  components: {
    AgentEditDialog,
    AgentMoneyDialog
  }
})
 // @ts-ignore
export default class ArticleList extends Vue {
  pagerHeader: any = {
    title: "代理列表",
    desc: "代理列表",
    breadcrumb: ["代理管理", "代理列表"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    parentKeywords: "",
    keywords: "",
    level: "",
    startDate: null,
    endDate: null
  };
  tableData: any = {
    items: [],
    totalCount: 0
  };
  categories: any[] = [];
  dateRange: any[] = [];
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
    //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.getData()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.getData()
    } 
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          ...this.filter
        }
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
//   activated() {
//       console.log('ss');
      
//     this.getData();
//   }
  created() {
    if(this.$route.query.time==="0"){
        this.getTime(0)
    }else if(this.$route.query.time==="1"){
        this.getTime(1)
    }else if(this.$route.query.time==="2"){
        this.getTime(6)
    }else if(this.$route.query.time==="3"){
        this.getTime(29)
    }
    this.filter.size = this.systemSettings.pageSize;
    this.getData();
    this.readerCategory();
  }
// 处理传过来的时间数字
    getTime(days:any){
        if(days==1){
           // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            // @ts-ignore
            this.filter.endDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")
           // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")]
        }else{
            // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            this.filter.endDate =new Date().Format("yyyy-MM-dd 23:59:59")
            // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date().Format("yyyy-MM-dd 23:59:59")]
        }
            
    }

  async readerCategory() {
    try {
      var res = await this.$ajax.get("/api/services/app/Category/GetAll", {
        params: {
          type: 2
        }
      });
      this.categories = res.data.result;
    } catch (error) {}
  }
  get levelOptions() {
    return this.getEnum("AgentLevel");
  }
  // 点击添加代理
  openAddAgentDialog() {
    (this.$refs.agentEditDialog as any).show(null);
  }
    //点击添加任务金额
   editEditMoneyDialog(id:any){
        (this.$refs.agentMoneyDialog as any).show(id);
   }
//   点击修改代理
  async editEditAgentDialog(row: any) {
    var loading = this.$loading({
      text: "加载中..."
    });
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetInfo", {
        params: {
          id: row.id
        }
      });
      if (res.data.success) {
        var agentInfo = res.data.result;
        (this.$refs.agentEditDialog as any).show(agentInfo);
      }
    } catch (error) {}
    console.log(row);
    loading.close();
  }

// 删除代理
  async deleteMerchant(row: any) {
    var result = await this.$confirm(
      `确认要删除代理【${row.name}】吗？`
    );
    if (result == "confirm") {
      var loading = this.$loading({
        text: "删除中..."
      });
      try {
        var res = await this.$ajax.delete("/api/services/app/Agent/Delete", {
          params: {
            ids: row.id
          }
        });
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.getData();
        }
      } catch (error) {}
      loading.close();
    }
  }
  async computeStatistics(id: number) {
    var loading = this.$loading({ text: "更新中" });
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Agent/ComputeStatisticsInfo",
        {
          id
        }
      );
      this.getData();
    } catch (error) {}
    loading.close();
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
}
