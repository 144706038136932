



























import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class AgentEditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    amount: 1,
    reason: 1,
    id:null,
    remark:''
  };
  addMode: boolean = true;
  loading: boolean = false;
  rules: any = {
    amount: [{ required: true, message: "请输入数量", trigger: "blur" },{ pattern: /^[1-9]\d*$/, message: '数量必须为数字值且大于等于1'}],
    reason: [{ required: true, message: "请选择原因", trigger: "blur" }],
    mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
  };
  show(id: any = null) {
    this.dialogFormVisible = true;
        this.formData = {
        amount: 1,
        reason: 1,
        id:id,
        remark:''
      };
  }
//   调用枚举中的原因
    get levelOptions() {
        return this.getEnum("AgentTaskAmountChangeReason");
    }
  // 点击修改
  saveAgent() {
    (this.$refs["editAgentForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Agent/AddTaskAmount",
            this.formData
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$notify.success(`${!this.addMode ? "修改" : "添加"}成功`);
            this.addMode=true;
            this.dialogFormVisible = false;

            this.$emit("confirm");
          }
        } catch (error) {}
        this.loading = false;
      }
    });
  }
//   点击叉叉
    handleClose(done:any) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.addMode=true
          })
          .catch(_ => {});
      }
}
