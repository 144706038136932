































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class AgentEditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    parentId: null,
    level: 0,
    name: "",
    mobile: "",
    idCard: "",
    emailAddress: "",
    id: "",
    bonusAgentId: null,
    bonusRate: null
  };
  checkedBonusAgent: boolean = false;
  addMode: boolean = true;
  loading: boolean = false;
  rules: any = {
    id: [{ required: true, message: "请选择用户", trigger: "blur" }],
    name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
    mobile: [{ required: true, message: "请输入手机号", trigger: "blur" }],
    idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
    emailAddress: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
    bonusAgentId: [
      { required: true, message: "请选择代理分红人", trigger: "change" }
    ],
    bonusRate: [{ required: true, message: "请输入分红比例", trigger: "blur" }]
  };
  show(agentInfo: any = null) {
    this.dialogFormVisible = true;
    if (agentInfo == null) {
      this.formData = {
        parentId: null,
        level: 0,
        name: "",
        mobile: "",
        idCard: "",
        emailAddress: "",
        id: "",
        bonusAgentId: null,
        bonusRate: null
      };
      this.checkedBonusAgent = false;
    } else {
      if (agentInfo.parentId) this.renderAgentOptions(agentInfo.parentId);
      if (agentInfo.bonusAgentId)
        this.renderBonusAgentOptions(agentInfo.bonusAgentId);
      this.addMode = false;
      this.formData = {
        parentId: agentInfo.parentId,
        level: agentInfo.level,
        name: agentInfo.name,
        mobile: agentInfo.member.phoneNumber,
        idCard: agentInfo.idCard,
        emailAddress: agentInfo.member.emailAddress,
        id: agentInfo.id,
        bonusAgentId: agentInfo.bonusAgentId,
        bonusRate: agentInfo.bonusRate
      };
      this.checkedBonusAgent = !!agentInfo.bonusAgentId;
    }
  }
  // 点击修改
  saveAgent() {
    (this.$refs["editAgentForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/Agent/CreateOrUpdate",
            this.formData
          );
          if (res.data.success) {
            var data = res.data.result;
            this.$notify.success(`${!this.addMode ? "修改" : "添加"}成功`);
            this.addMode=true;
            this.dialogFormVisible = false;

            this.$emit("confirm");
          }
        } catch (error) {}
        this.loading = false;
      }
    });
  }
//   点击叉叉
    handleClose(done:any) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.addMode=true
          })
          .catch(_ => {});
      }
  agentItems: any[] = [];
  agentSearchLoading: boolean = false;
  async renderAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  bonusAgentItems: any[] = [];
  bonusAgentSearchLoading: boolean = false;
  async renderBonusAgentOptions(query: string) {
    this.bonusAgentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.bonusAgentItems = res.data.result.items;
    } catch (error) {}
    this.bonusAgentSearchLoading = false;
  }

  get levelOptions() {
    return this.getEnum("AgentLevel");
  }

  memberItems: any[] = [];
  memberSearchLoading: boolean = false;
  async renderMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }

  @Watch("checkedBonusAgent")
  checkedBonusAgentChange(value: boolean) {
    if (!value) {
      this.formData.bonusAgentId = null;
      this.formData.bonusRate = null;
    }
  }
}
